@import "src/styles/index";

// http://ionicframework.com/docs/theming/

// App Global Sass
// --------------------------------------------------
// Put style rules here that you want to apply globally. These
// styles are for the entire app and not just one component.
// Additionally, this file can be also used as an entry point
// to import other Sass files to be included in the output CSS.
//
// Shared Sass variables, which can be used to adjust Ionic's
// default Sass variables, belong in "theme/variables.scss".
//
// To declare rules for a specific mode, create a child rule
// for the .md, .ios, or .wp mode classes. The mode class is
// automatically applied to the <body> element in the app.


.bar-button-default-md {
  color: white;
}

.toolbar-title-md {

  color: white;
}

.content-md {
  background-color: $v-colour-mm-background;
}

.text-input-md {
  background-color: white;
}

// vvv https://codepen.io/eode9/pen/twkKm

.corner-ribbon{
  width: 200px;
  background: #e43;
  position: absolute;
  top: 10px;
  text-align: center;
  line-height: 30px;
  letter-spacing: 1px;
  color: #f0f0f0;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  z-index: 2000;
  opacity: 0.8;
}

.corner-ribbon.top-right{
  right: -70px;
  left: auto;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

// ^^^ https://codepen.io/eode9/pen/twkKm


div[cclass="router_wrapper"] {
  height: 100%;
  background-color: white;
}

a.mobility-mojo-logo {
  transform: translateY(6px);
}

img[aclass="header-toolbar-logo"] {
  height: 34px;
}

div[cclass="app-content"] {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-divider {
  margin: 10px 0;
}

// fix bug in scroll
div[aclass="page-content"] {
  position: relative;
  top: 8px;
}

span[aclass="page-information"] {
  flex: 1 1 auto;
  text-align: center;
}

mat-toolbar[aclass="footer-toolbar"] {
  z-index: 1;
}
