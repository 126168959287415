@import "variables";

div[aclass="component-list"] {
  width: 100%;
  padding: 4px 16px;
  margin-top: 4px;
  margin-bottom: 4px;

  div[aclass="component-list-item"] {

    font-size: 16px;
    margin-top: 4px;
    margin-bottom: 4px;

    div[aclass="form-divider"] {
      height: 1px;
      width: 100%;
      background-color: #dedede;
      margin-top: 4px;
      margin-bottom: 4px;
    }

    div[aclass="form-error"] {
      color: red;
      font-weight: bold;
      padding-bottom: 8px;
      word-wrap: break-word;
    }

    div[aclass="form-header"] {
      display: block;
      overflow: hidden;
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
      font-weight: bold;
      margin: 13px 8px 13px 0px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

div[aclass="page-container"] {
  background-color: #f1f0f6;
  height: 100vh;
  display: grid;
  grid-template-rows: 56px 1fr 56px;
  grid-template-areas:
        "header-toolbar"
        "page-content"
        "footer-toolbar";
}

div[aclass="page-content"] {
  grid-area: page-content;
  width: 100%;
  padding: 16px 32px 16px 32px;
  overflow: scroll;
  height: 100%;

  @media screen and (max-width: $width-phone) {
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 80px;
  }
}

mat-sidenav-container[aclass="sidenav-container"] {
  height: 100%;
  background-color: #f1f0f6;

  mat-sidenav[aclass="sidenav"] {
    width: 304px;
  }
}

mat-toolbar[aclass="header-toolbar"] {
  grid-area: header-toolbar;
}

div[cclass="mobility-mojo-logo"] {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%
}

mat-toolbar[aclass="footer-toolbar"] {
  grid-area: footer-toolbar;
  position: fixed;
  bottom: 0;
}

mat-toolbar[aclass="header-toolbar"], mat-toolbar[aclass="footer-toolbar"] {
  background-color: $v-colour-mm-purple;
  color: white;
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  font-size: 1.4rem;

  button.mat-button[disabled] {
    color: white;
  }

  mat-toolbar-row {
    @media screen and (max-width: $width-phone) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

div[aclass="dialog-content"] {
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  font-size: 14px;
}

mat-select-country[aclass="full-width"] mat-form-field {
  //width: 100%;
  display: unset;
}
