@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-quartz.css';
@import 'styles/ionic-angular/css/ionic.css';
@import 'common/scss/variables';
@import 'app/app';
@import 'common/scss/index';

.confirm-cancel-dialog .mat-dialog-container {
  box-shadow: none;
  background: rgba(0, 0, 0, 0.0);
}
